<template>
  <div
    v-if="!hideLanguages"
    class="bg-grays-lightest text-grays-dark flex items-center justify-end space-x-6 px-6 py-2 text-xs font-semibold"
    role="navigation"
    aria-label="Language selection"
  >
    <button aria-label="Switch to English" lang="en">English</button>
    <button aria-label="Switch to Spanish" lang="es">Español</button>
    <button aria-label="Switch to Chinese" lang="zh">中文</button>
  </div>
  <div
    ref="el"
    class="border-grays-light top-0 z-30 w-full bg-white lg:sticky"
    :class="{ 'drop-shadow-xl': isScrolled }"
  >
    <div class="flex items-center justify-between px-6 pt-4">
      <div class="flex gap-3">
        <nuxt-link
          :to="guestStoreUrl"
          class="flex flex-1 justify-start"
          aria-label="Homepage"
        >
          <NuxtImg
            src="/img/icons/zenni-business-logo.svg"
            alt="zenni logo"
            class="text-grays-darkest hidden h-[40px] lg:block"
          />
          <NuxtImg
            src="/img/icons/zenni-business-logo-mobile.svg"
            alt="zenni logo"
            class="text-grays-darkest h-[40px] lg:hidden"
          />
        </nuxt-link>
        <ClientOnly>
        <div
          v-show="showPartnerLogo && partnerLogo"
          class="border-grays-light flex h-[40px] w-[114px] items-center border-l pl-3"
        >
          <NuxtImg
            :src="partnerLogo"
            provider="akamai"
            alt="Partner logo"
            class="max-h-[40px] max-w-[114px]"
            :modifiers="{
              resize: 'width=114,height=40',
            }"
            @load="changeShowPartnerLogo(true)"
            @error="changeShowPartnerLogo(false)"
          />
        </div>
        </ClientOnly>
      </div>
      <div
        v-if="showSearch"
        class="order-3 hidden w-fit px-10 md:order-2 md:flex"
        role="search"
      >
        <PlpSearch
          v-model="searchKeyword"
          class="w-full md:w-[343px]"
          aria-label="Search products"
          @debounce-perform-search="debounceUpdateSearch"
          @perform-search="clearSearch"
        />
      </div>
      <div
        class="relative order-2 flex items-center justify-end bg-white lg:order-3"
      >
        <nav
          v-if="showNav"
          class="hidden lg:relative lg:top-0 lg:flex lg:flex-row lg:items-center lg:gap-0 lg:space-x-8 lg:border-0 lg:p-0"
          aria-label="Main navigation"
        >
          <div
            v-for="(link, i) in links"
            :key="`nav-link-${i}`"
            class="group relative"
          >
            <!-- nuxt-link throw an insertBefore error, using anchor instead-->
            <a
              v-if="!link.subcategories"
              :href="link.href"
              :target="link.target"
              class="font-semibold"
              @click.stop="closeAndContinue"
            >
              {{ link.label }}
            </a>
            <div v-else>
              <button class="inline-flex items-center gap-2 font-semibold">
                {{ link.label }}
                <ZenniIconsIconCaret2
                  class="!h-[27px] !w-2.5 transition-transform duration-200 group-hover:rotate-180"
                />
              </button>

              <div
                class="invisible absolute left-0 top-[calc(100%-8px)] z-[100] opacity-0 transition-all duration-200 group-hover:visible group-hover:opacity-100"
              >
                <div class="pt-2">
                  <div class="w-[250px] rounded-md bg-white py-2 shadow-lg">
                    <nuxt-link
                      v-for="(subcategory, j) in link.subcategories"
                      :key="`subcat-${j}`"
                      :to="subcategory.href"
                      class="block px-4 py-2 text-sm text-[#18181B] hover:underline"
                    >
                      {{ subcategory.label }}
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <ZnButton
          v-if="isGuestMode && showNav"
          type="primary"
          class="h-12 whitespace-nowrap px-4 py-3 text-base font-semibold lg:ml-8"
          @click="setShowSignIn(true)"
          >Sign in</ZnButton
        >

        <div
          class="flex items-center gap-4 pl-8"
          :class="isGuestMode ? 'lg:hidden' : ''"
        >
          <NuxtLink
            v-if="!hideCart"
            to="/cart-preview"
            aria-label="Cart"
            :data-count="itemCount"
            class="after:bg-teal-primary relative after:absolute after:-top-1 after:left-3 after:flex after:h-4 after:w-4 after:items-center after:justify-center after:rounded-full after:text-xs after:font-semibold after:text-white after:content-[attr(data-count)] data-[count='0']:after:hidden data-[count='0']:after:content-['']"
            :class="isGuestMode ? 'hidden' : ''"
          >
            <ZenniIconsIconCart class="text-grays-dark" />
          </NuxtLink>

          <button
            v-if="showNav"
            class="lg:hidden"
            aria-label="Navigate"
            @click="showMenuItem = !showMenuItem"
          >
            <ZenniIconsIconNavigate size="base" class="text-grays-dark" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="showSearch" class="z-20 bg-white px-4 pt-2 md:hidden">
      <PlpSearch
        v-model="searchKeyword"
        class="w-full"
        aria-label="Search products"
        @debounce-perform-search="debounceUpdateSearch"
        @perform-search="clearSearch"
      />
    </div>
    <div
      class="border-grays-light sticky z-10 mt-4 flex w-full justify-end border-b bg-white px-6 pb-4 lg:mt-0"
    >
      <template v-if="!isAdminGift">
        <template v-if="isGuestMode && hasMultipleGuestCatalogs">
          <SwitchCatalogHeader class="lg:mt-2" />
          <SwitchCatalogTooltip />
        </template>
        <HeaderTooltip :is-confirmation="isConfirmationPage" />
        <ProfileAccount v-if="!hideCart" />
      </template>
    </div>
  </div>
  <HeaderNavigationDrawer
    :is-open="showMenuItem"
    :links="links"
    @close="showMenuItem = false"
  />
  <HeaderSignInDrawer />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { debounce } from 'lodash-es'
import { useGuestStore } from '~/store/guest'
import {
  ref,
  computed,
  useRouter,
  useRoute,
  onMounted,
  onUnmounted,
} from '#imports'
import { useGuestCartStore } from '~/store/guestCart'
import { useSignInStore } from '~/store/signin'
const { itemCount } = storeToRefs(useGuestCartStore())
const { setShowSignIn } = useSignInStore()
const showMenuItem = ref<boolean>(false)

const route = useRoute()
const router = useRouter()
const guestStore = useGuestStore()
const {
  isGuestMode,
  isSafetyCatalog,
  currentGift,
  guestStoreUrl,
  hasMultipleGuestCatalogs,
  isAdminGift,
} = storeToRefs(guestStore)

const isPLP = computed(() => route.path.includes('/products/list'))
const isConfirmationPage = computed(() =>
  route.path.includes('/checkout/orderconfirmation'),
)
const query = route.query?.query

const searchKeyword = ref<string>(
  (query && isPLP.value ? (query as string) : '') || '',
)

const links = computed<
  {
    label: string
    href: string
    target?: string
    isCategory?: boolean
    subcategories?: {
      label: string
      href: string
    }[]
  }[]
>(() => {
  if (isSafetyCatalog.value) {
    return [
      {
        label: 'Safety Glasses',
        href: '/products/list',
        target: undefined,
        isCategory: true,
      },
      { label: 'Help', href: '/contact', target: undefined },
    ]
  }
  return [
    {
      label: 'Eyeglasses',
      href: '/products/list',
      target: undefined,
      isCategory: true,
      subcategories: [
        { label: 'Shop All Eyeglasses', href: '/products/list' },
        { label: "Women's Eyeglasses", href: '/products/list/women' },
        { label: "Men's Eyeglasses", href: '/products/list/men' },
        { label: "Kids' Eyeglasses", href: '/products/list/kids' },
      ],
    },
    {
      label: 'Sunglasses',
      href: '/products/list/sunglasses?activeImage=tinted',
      target: undefined,
      isCategory: true,
      subcategories: [
        {
          label: 'Shop All Sunglasses',
          href: '/products/list/sunglasses?activeImage=tinted',
        },
        {
          label: "Women's Sunglasses",
          href: '/products/list/women-sunglasses?activeImage=tinted',
        },
        {
          label: "Men's Sunglasses",
          href: '/products/list/men-sunglasses?activeImage=tinted',
        },
        {
          label: "Kids' Sunglasses",
          href: '/products/list/kids-sunglasses?activeImage=tinted',
        },
      ],
    },
    { label: 'Help', href: '/contact', target: undefined },
  ]
})

const closeAndContinue = (event: Event) => {
  showMenuItem.value = false
  event.target?.dispatchEvent(event)
}

const showPartnerLogo = ref<boolean>(false)

const showSearch = computed(() => route.meta.showSearch ?? true)
const showNav = computed(() => route.meta.showNav ?? true)
const hideCart = computed(() => route.meta.hideCart ?? false)
// TODO: remove hardcoded value when we have a language selector
const hideLanguages = ref(true) // computed(() => route.meta.hideLanguages ?? false)

const changeShowPartnerLogo = (value: boolean) => {
    showPartnerLogo.value = value
}

const partnerLogo = computed(() => {
  if (!currentGift.value?.partnerId) {
    return undefined
  }
  return `logo/${currentGift.value.partnerId}.image`
})

const searchProducts = () => {
  // This is to not triggering the search if we clear the search input on other pages than PLP
  if (!query && !isPLP.value && !searchKeyword.value) {
    return
  }

  router.replace({
    path: '/products/list',
    query: {
      ...route.query,
      query: searchKeyword.value,
    },
  })
}

const debounceUpdateSearch = debounce(searchProducts, 500)
const clearSearch = () => {
  searchKeyword.value = ''
  searchProducts()
}

const isScrolled = ref<boolean>(false)

const handleScroll = () => {
  isScrolled.value = window.scrollY > 30
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
